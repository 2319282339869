import httpService from "../../http/requests/app"

const domain = "http://localhost:10443/auth/";

export default {
  state: {

  },

  getters: {

  },
  mutations: {

  },

  actions: {


    addCategory({commit}, data) {
      return new Promise((resolve,reject) => {

        httpService.addCategory(data)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },
    editCategory({commit}, data) {
      return new Promise((resolve,reject) => {

        httpService.editCategory(data)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },
    deleteCategory({commit}, data) {
      return new Promise((resolve,reject) => {

        httpService.deleteCategory(data)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },
    getCategories({commit}) {
      return new Promise((resolve,reject) => {

        httpService.getCategories()
          .then(response => {

            resolve(response.data);


          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    }

  }
};
