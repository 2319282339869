
import axios from 'axios'

const domain = ""

let header = {};

if(localStorage.getItem('accessToken')){
  header = {
    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
  }
}
else{

}

export default axios.create({
  headers: {
      header

    /*'Authorization': localStorage.getItem('accessToken') == null || typeof localStorage.getItem('accessToken') == undefined ? "Bearer sfsdfsdfsdf" :  `Bearer ${localStorage.getItem('accessToken')}`*/
  }
  // You can add your headers here
})

