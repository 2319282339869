import jwt from "../../http/requests/auth/jwt/index.js"
import axios from "../../http/axios/index"
import httpService from "../../http/requests/app";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const domain = "http://localhost:10443/auth/";

export default {
  state: {
    loggedInUser:
      localStorage.getItem("accessToken") != null
        ? localStorage.getItem("accessToken")
        : null,
    loading: false,
    error: null
  },

  getters: {
    loggedInUser: state => state.loggedInUser,
    loading: state => state.loading,
    error: state => state.error
  },
  mutations: {
    setUser(state, data) {
      state.loggedInUser = data;
      state.loading = false;
      state.error = null;
    },
    setLogout(state) {
      state.loggedInUser = null;
      state.loading = false;
      state.error = null;
      // this.$router.go("/");
    }
  },

  actions: {
    login({commit}, data) {
      return new Promise((resolve,reject) => {
        jwt.login({username:data.email, password:data.password}).then(function (response) {


          if(response.data.jwttoken){

            //if(response.data.role != "Cashier"){
              localStorage.setItem("accessToken", response.data.jwttoken)
              localStorage.setItem('username', data.email);
              localStorage.setItem('userId', response.data.userId);
              localStorage.setItem('full_names', response.data.full_names);
              localStorage.setItem('role', response.data.role);
              localStorage.setItem('permissions', response.data.permissions);
              axios.defaults.headers['Authorization'] = 'Bearer ' + response.data.jwttoken
              commit("setUser", response.data);

              resolve(response.data);

            /*}
            else{
              reject({message: "User login prohibited"})
            }*/


          }
          else{
            reject({message: "An error occurred. Please try again"})
          }

        })
          .catch(function (error) {

            //console.log(error)
            reject({message: "Wrong email and password combination"})

          });
      });

    },

    forgotPassword({commit}, data){

      return new Promise((resolve,reject) => {
        jwt.forgotPassword(data).then(function (response) {

          //console.log(response.data);

          if(response.data.statusCode == 200){

            resolve()

          }
          else{
            reject({message: response.data.message})
          }

        })
          .catch(function (error) {

           // console.log(error)


          });
      });


    },
    resetPasswordViaEmail({commit}, data){

      return new Promise((resolve,reject) => {
        jwt.resetPasswordViaEmail(data).then(function (response) {

          //console.log(response.data);

          if(response.data.statusCode == 200){

            resolve()

          }
          else{
            reject({message: response.data.message})
          }

        })
          .catch(function (error) {

            // console.log(error)


          });
      });


    },
    signOut({commit}) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("username");
      localStorage.removeItem("full_names");
      commit("setLogout");
    }
  }
};
