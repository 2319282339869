import httpService from "../../http/requests/app"

const domain = "http://localhost:10443/auth/";

export default {
    state: {

      loading: false,
      error: null
    },

    getters: {

    },
    mutations: {

    },

  actions: {
    saveContact({commit}, data) {
      return new Promise((resolve,reject) => {

        httpService.saveContact(data)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },

    editContact({commit}, data) {
      return new Promise((resolve,reject) => {

        httpService.editContact(data)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },


    deleteContact({commit}, data) {
      return new Promise((resolve,reject) => {

        httpService.deleteContact(data)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },


    getContacts({commit}){

      return new Promise((resolve,reject) => {
        httpService.getContacts()
          .then(response => {

            resolve(response.data);

          })
          .catch(error => {
            reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    }



  }
};
