import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-ecommerce' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others
  ],
})

router.beforeEach((to, _, next) => {


  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    const loginpath = window.location.pathname;
    console.log(loginpath);
    console.log(isLoggedIn);
    if (!isLoggedIn) return next({ name: 'auth-login', query: { from: loginpath } })

    // If logged in => not authorized
    //return next({ name: 'auth-login' })
  }

  let role = localStorage.getItem("role");

  let merchantAllowedPaths = ["/apps/merchantUsers","/apps/transactions", "/apps/merchantVouchers", "/apps/merchantInvoices","/apps/generateParkingVoucher"];


  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {

    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }
  else if(isLoggedIn){
    console.log(role);
    console.log(to.path);
    if(role == "Merchant" || role == "Cashier"){
      if(!merchantAllowedPaths.includes(to.path)){
        console.log("Not authorised");
        location.replace("/apps/transactions")
      }
    }
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
