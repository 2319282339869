import httpService from "../../http/requests/app"

const domain = "http://localhost:10443/auth/";

export default {
  state: {

  },
  getters: {

  },
  mutations: {

  },

  actions: {
    addUser({commit}, data) {
      return new Promise((resolve,reject) => {

        httpService.saveUser(data)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },
      addOutletUser({commit}, data) {
          return new Promise((resolve,reject) => {

              httpService.addOutletUser(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      resetPassword({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.resetPassword(data.userId)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

    editUser({commit}, data) {
      return new Promise((resolve,reject) => {

        httpService.updateUser(data)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },


    deleteUser({commit}, data) {
      return new Promise((resolve,reject) => {

        httpService.deleteUser(data)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()


            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },


    getUsers({commit}){

      return new Promise((resolve,reject) => {
        httpService.getUsers()
          .then(response => {

            resolve(response.data);

          })
          .catch(error => {
            reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },
      getApiClients({commit}) {
          return new Promise((resolve,reject) => {
              httpService.getApiClients()
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      getUsersByOutlets({commit}, data){

          return new Promise((resolve,reject) => {
              httpService.getUsersByOutlets(data.userId)
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

    getUsersByRole({commit}, data){

      return new Promise((resolve,reject) => {
        httpService.getUsersByRole(data)
          .then(response => {
            resolve(response.data);

          })
          .catch(error => {
            reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    }



  }
};
