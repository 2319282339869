import Vue from 'vue'
import Vuex from 'vuex'


import authData from "./modules/authData";
import locationData from "./modules/locationData";
import usersData from "./modules/usersData";
import groupsData from "./modules/groupsData";
import contactsData from "./modules/contactsData";
import homepageData from "./modules/homepageData";
import outletData from "./modules/outletData";
import settingsData from "./modules/settingsData";
import loyaltyData from "./modules/loyaltyData";
import customersData from "./modules/customersData";
import campaignData from "./modules/campaignData";
import vouchersData from "./modules/vouchersData";
// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    authData,
    locationData,
    usersData,
    groupsData,
    contactsData,
    homepageData,
    outletData,
    settingsData,
    loyaltyData,
    customersData,
    campaignData,
    vouchersData
  },
  strict: process.env.DEV,
})



