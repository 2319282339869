import httpService from "../../http/requests/app"

const domain = "http://localhost:10443/auth/";

export default {
  state: {

  },

  getters: {

  },
  mutations: {

  },

  actions: {
      getVouchers({commit},data) {

      return new Promise((resolve,reject) => {

        httpService.getVouchers(data.page, data.from_date,data.to_date, data.status, data.voucherType, data.outletId)
          .then(response => {

            resolve(response.data);


          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },
      getGiftRegistries({commit}) {
          return new Promise((resolve,reject) => {

              httpService.getGiftRegistries()
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      getInstitutionalVouchers({commit},data) {

          return new Promise((resolve,reject) => {

              httpService.getInstitutionalVouchers(data.page, data.from_date,data.to_date, data.status)
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      getOutletVoucherCodes({commit},data) {
          return new Promise((resolve,reject) => {

              httpService.getOutletVoucherCodes(data.page,data.outletId, data.fromDate,data.toDate, data.status)
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      getAllCustomerSegments({commit}) {
          return new Promise((resolve,reject) => {

              httpService.getAllCustomerSegments()
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      getInstitutionVoucherRecipients({commit},data) {
          return new Promise((resolve,reject) => {

              httpService.getInstitutionVoucherRecipients(data.page,data.voucherId)
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      searchVoucherRecipient({commit},data) {
          return new Promise((resolve,reject) => {

              httpService.searchVoucherRecipient(data.keyword, data.voucherId)
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      importVoucherCodes({commit}, voucherCodeData){
          return new Promise((resolve,reject) => {
              httpService.importVoucherCodes(voucherCodeData)
                  .then(response => {

                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }



                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      refreshSegments({commit}, segmentData) {
          return new Promise((resolve,reject) => {
              httpService.refreshSegments(segmentData.id)
                  .then(response => {

                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }



                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      createCustomerSegment({commit}, segmentData){
          return new Promise((resolve,reject) => {
              httpService.createCustomerSegment(segmentData)
                  .then(response => {

                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }



                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      importVoucherRecipients({commit}, voucherCodeData) {
          return new Promise((resolve,reject) => {
              httpService.importVoucherRecipients(voucherCodeData)
                  .then(response => {

                      if(response.data.statusCode == 200){

                          resolve(response.data)

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }



                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      buyAdminVoucher({commit},voucherData){
          return new Promise((resolve,reject) => {
              httpService.buyAdminVoucher(voucherData)
                  .then(response => {

                      if(response.data.statusCode == 200){
                          resolve()
                      }
                      else{
                          reject({message: response.data.message})
                      }
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      createParkingVoucher({commit},voucherData) {
          return new Promise((resolve,reject) => {
              httpService.createParkingVoucher(voucherData)
                  .then(response => {
                      console.log(response.data);

                      if(response.data.statusCode == 200){
                          resolve()
                      }
                      else{
                          reject({message: response.data.message})
                      }
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      buyInstitutionalVouchers({commit},voucherData) {
          return new Promise((resolve,reject) => {
              httpService.buyInstitutionalVouchers(voucherData)
                  .then(response => {

                      if(response.data.statusCode == 200){
                          resolve()
                      }
                      else{
                          reject({message: response.data.message})
                      }
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      approveInstitutionVoucher({commit},voucherData) {
          return new Promise((resolve,reject) => {
              httpService.approveInstitutionVoucher(voucherData.id)
                  .then(response => {

                      if(response.data.statusCode == 200){
                          resolve()
                      }
                      else{
                          reject({message: response.data.message})
                      }
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      updateVoucherExpiryDate({commit},voucherData) {
          return new Promise((resolve,reject) => {
              httpService.updateVoucherExpiryDate(voucherData)
                  .then(response => {

                      if(response.data.statusCode == 200){
                          resolve()
                      }
                      else{
                          reject({message: response.data.message})
                      }
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      exportIndividualVouchers({commit},filterData) {
          return new Promise((resolve,reject) => {
              httpService.exportIndividualVouchers(filterData.startDate, filterData.endDate, filterData.emailAddress)
                  .then(response => {

                      if(response.data.statusCode == 200){
                          resolve()
                      }
                      else{
                          reject({message: response.data.message})
                      }
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      exportIndividualVoucherRedemptions({commit},filterData) {
          return new Promise((resolve,reject) => {
              httpService.exportIndividualVoucherRedemptions(filterData.startDate, filterData.endDate, filterData.emailAddress)
                  .then(response => {

                      if(response.data.statusCode == 200){
                          resolve()
                      }
                      else{
                          reject({message: response.data.message})
                      }
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      exportInstitutionVoucherRedemptions({commit},filterData) {
          return new Promise((resolve,reject) => {
              httpService.exportInstitutionVoucherRedemptions(filterData.startDate, filterData.endDate, filterData.emailAddress)
                  .then(response => {

                      if(response.data.statusCode == 200){
                          resolve()
                      }
                      else{
                          reject({message: response.data.message})
                      }
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      exportGiftRegistries({commit},filterData) {
          return new Promise((resolve,reject) => {
              httpService.exportGiftRegistries(filterData.emailAddress)
                  .then(response => {

                      if(response.data.statusCode == 200){
                          resolve()
                      }
                      else{
                          reject({message: response.data.message})
                      }
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      exportCorporateVouchers({commit},filterData) {
          return new Promise((resolve,reject) => {
              httpService.exportCorporateVouchers(filterData.startDate, filterData.endDate, filterData.emailAddress)
                  .then(response => {

                      if(response.data.statusCode == 200){
                          resolve()
                      }
                      else{
                          reject({message: response.data.message})
                      }
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      redeemVoucher({commit},voucherData) {
          return new Promise((resolve,reject) => {
              httpService.redeemVoucher(voucherData)
                  .then(response => {

                      if(response.data.statusCode == 200){
                          resolve()
                      }
                      else{
                          reject({message: response.data.message})
                      }
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      verifyVoucherCode({commit},voucherData) {
          return new Promise((resolve,reject) => {
              httpService.verifyVoucherCode(voucherData.voucherCode)
                  .then(response => {
                      resolve(response.data)
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      pushSTK({commit},paymentData) {
          return new Promise((resolve,reject) => {
              httpService.pushSTK(paymentData)
                  .then(response => {
                      resolve(response.data)
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      getVoucherRedemptions({commit},filterData) {
          return new Promise((resolve,reject) => {

              httpService.getVoucherRedemptions(filterData)
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      getCorporateVoucherRedemptions({commit},filterData) {
          return new Promise((resolve,reject) => {

              httpService.getCorporateVoucherRedemptions(filterData)
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      getVoucherStats({commit},data){
          return new Promise((resolve,reject) => {

              httpService.getVoucherStats(data.from_date,data.to_date, data.status)
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      checkMpesaTransaction({commit},data){
          return new Promise((resolve,reject) => {

              httpService.checkMpesaTransaction(data.billRef)
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      getVoucherRedemptionsPostings({commit}) {
          return new Promise((resolve,reject) => {

              httpService.getVoucherRedemptionsPostings()
                  .then(response => {
                      resolve(response.data);
                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      getVoucherRedemptionsPosting({commit},data){
          return new Promise((resolve,reject) => {

              httpService.getVoucherRedemptionsPosting(data.voucherId)
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      searchCustomerVouchers({commit}, data) {
          return new Promise((resolve,reject) => {

              httpService.searchCustomerVouchers(data.voucherCode)
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      getVoucherRedemptionItems({commit},data) {
          return new Promise((resolve,reject) => {

              httpService.getVoucherRedemptionItems(data.voucherId)
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      }
  }
};
