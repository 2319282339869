export default [
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
  },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/apps/transactions',
    name: 'loyalty-transactions',
    component: () => import('@/views/apps/pages/loyalty.vue')
  },
  {
    path: '/apps/partnerTransactions',
    name: 'loyalty-partner-transactions',
    component: () => import('@/views/apps/pages/nonAccrualPartnerTransactions.vue')
  },
  {
    path: '/apps/rules',
    name: 'loyalty-rules',
    component: () => import('@/views/apps/pages/loyaltyRules.vue')
  },
  {
    path: '/apps/fraudRules',
    name: 'loyalty-fraud',
    component: () => import('@/views/apps/pages/fraudRules.vue')
  },
  {
    path: '/apps/createFraudRule',
    name: 'loyalty-fraud',
    component: () => import('@/views/apps/pages/createFraudRule.vue')
  },
  {
    path: '/apps/createRule',
    name: 'loyalty-rules',
    component: () => import('@/views/apps/pages/createRule.vue')
  },
  {
    path: '/apps/editRule/:id',
    name: 'loyalty-rules',
    component: () => import('@/views/apps/pages/editRule.vue')
  },
  {
    path: '/apps/customers',
    name: 'loyalty-customers',
    component: () => import('@/views/apps/pages/customers.vue')
  },

  {
    path: '/apps/sms',
    name: 'campaign-sms',
    component: () => import('@/views/apps/pages/campaigns.vue')
  },
  {
    path: '/apps/pushNotifications',
    name: 'campaign-push-notification',
    component: () => import('@/views/apps/pages/campaignPushNotification.vue')
  },
  {
    path: '/apps/outlets',
    name: 'loyalty-outlets',
    component: () => import('@/views/apps/pages/outlets.vue')
  },
  {
    path: '/apps/importCustomers',
    name: 'loyalty-outlets',
    component: () => import('@/views/apps/pages/importCustomers.vue')
  },
  {
    path: '/apps/vouchers',
    name: 'loyalty-vouchers',
    component: () => import('@/views/apps/pages/GiftCards.vue')
  },
  {
    path: '/apps/generateVoucher',
    name: 'loyalty-vouchers',
    component: () => import('@/views/apps/pages/GenerateVoucher.vue')
  },
  {
    path: '/apps/generateParkingVoucher',
    name: 'loyalty-vouchers',
    component: () => import('@/views/apps/pages/GenerateParkingVouchers.vue')
  },
  {
    path: '/apps/generateCorporateVouchers',
    name: 'loyalty-vouchers',
    component: () => import('@/views/apps/pages/GenerateCorporateVouchers.vue')
  },
  {
    path: '/apps/institutionVoucherRecipients/:id',
    name: 'loyalty-vouchers',
    component: () => import('@/views/apps/pages/InstitutionVoucherRecipients.vue')
  },

  {
    path: '/apps/createOutlet',
    name: 'loyalty-create-outlet',
    component: () => import('@/views/apps/pages/createOutlet.vue')
  },
  {
    path: '/apps/lottery',
    name: 'lottery',
    component: () => import('@/views/apps/pages/Lottery.vue')
  },
  {
    path: '/apps/segments',
    name: 'customer-segmentation',
    component: () => import('@/views/apps/pages/customerSegmentation.vue')
  },
  {
    path: '/apps/createCustomerSegment',
    name: 'customer-segmentation',
    component: () => import('@/views/apps/pages/createCustomerSegment.vue')
  },
  {
    path: '/apps/segmentCustomers/:id',
    name: 'customer-segmentation',
    component: () => import('@/views/apps/pages/segmentCustomers.vue')
  },
  {
    path: '/apps/importOutlets',
    name: 'loyalty-import-outlet',
    component: () => import('@/views/apps/pages/importOutlets.vue')
  },
  {
    path: '/apps/createPermissions',
    name: 'loyalty-create-permissions',
    component: () => import('@/views/apps/pages/CreatePermissions.vue')
  },
  {
    path: '/apps/editRole/:id',
    name: 'loyalty-import-outlet',
    component: () => import('@/views/apps/pages/editPermissions.vue')
  },
  {
    path: '/apps/customer/:id',
    name: 'loyalty-customer-profile',
    component: () => import('@/views/apps/pages/CustomerProfile.vue')
  },
  {
    path: '/apps/createConversion',
    name: 'loyalty-create-conversionn',
    component: () => import('@/views/apps/pages/createLoyaltyConversion.vue')
  },
  {
    path: '/apps/editConversion/:id',
    name: 'loyalty-import-outlet',
    component: () => import('@/views/apps/pages/editLoyaltyConversion.vue')
  },
  {
    path: '/apps/importCustomers',
    name: 'loyalty-import-outlet',
    component: () => import('@/views/apps/pages/importCustomers.vue')
  },
  {
    path: '/apps/outletProfile/:id',
    name: 'loyalty-import-outlet',
    component: () => import('@/views/apps/pages/outletProfile.vue')
  },
  {
    path: "/apps/editOutlet/:id",
    name: 'loyalty-outlets',
    component: () => import("@/views/apps/pages/editOutlet.vue")
  },
  {
    path: '/apps/billing',
    name: 'loyalty-billing',
    component: () => import('@/views/apps/pages/invoices.vue')
  },
  {
    path: '/apps/partnersReport',
    name: 'partners-report',
    component: () => import('@/views/apps/pages/partnersReport.vue')
  },
  {
    path: '/apps/invoice/:id',
    name: 'loyalty-billing',
    component: () => import('@/views/apps/pages/invoiceDetails.vue')
  },
  {
    path: '/apps/voucherRedemption/:id',
    name: 'loyalty-billing',
    component: () => import('@/views/apps/pages/voucherRedemptionDetails.vue')
  },
  {
    path: '/apps/users',
    name: 'administration-users',
    component: () => import('@/views/apps/pages/users.vue')
  },
  {
    path: '/apps/merchantUsers',
    name: 'merchant-users',
    component: () => import('@/views/apps/pages/merchantUsers.vue')
  },
  {
    path: '/apps/merchantInvoices',
    name: 'merchant-invoices',
    component: () => import('@/views/apps/pages/merchantInvoices.vue')
  },
  {
    path: '/apps/merchantVouchers',
    name: 'merchant-vouchers',
    component: () => import('@/views/apps/pages/merchantVoucherRedemptions.vue')
  },
  {
    path: '/apps/settings',
    name: 'administration-settings',
    component: () => import('@/views/apps/pages/settings.vue')
  },
  {
    path: '/apps/comingSoon',
    name: 'coming-soon',
    component: () => import('@/views/apps/pages/ComingSoon.vue')
  },
  {
    path: '/apps/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/todo',
    name: 'apps-todo',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/apps/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },

  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/e-commerce/shop',
    name: 'apps-e-commerce-shop',
    component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Shop',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/wishlist',
    name: 'apps-e-commerce-wishlist',
    component: () => import('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
    meta: {
      pageTitle: 'Wishlist',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Wishlist',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/checkout',
    name: 'apps-e-commerce-checkout',
    component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
    meta: {
      pageTitle: 'Checkout',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Checkout',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/:slug',
    name: 'apps-e-commerce-product-details',
    component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
    meta: {
      pageTitle: 'Product Details',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
        {
          text: 'Product Details',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },

  // Invoice
  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
  },
  {
    path: '/apps/invoice/add/',
    name: 'apps-invoice-add',
    component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
  },
  {
    path: '/apps/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
  },
]
